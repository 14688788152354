.social-developers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 40px;

    @media (max-width: 1199px) {
        justify-content: center;
    }

    .social-developer-avatars-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .social-developer-avatar-container {
            width: 100px;
            opacity: 0;
            animation: avatarContainerAnimation 0.7s forwards;
            animation-timing-function: cubic-bezier(.06,.64,.58,.94);

            .social-developer-avatar {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background-color: #333;
                border: 0px solid #aaa;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
            }
        }
    }

    .social-developers-text {
        font-size: 1.05rem;
        font-weight: 400;
        color: white;
        opacity: 0;
        animation: trustedByDevelopersAnimation 1s forwards 0.7s; /* Delay animation by 1 second */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

@keyframes avatarContainerAnimation {
    from {
        width: 45px;
        opacity: 0;
    }
    to {
        width: 35px;
        opacity: 1;
    }
}

@keyframes trustedByDevelopersAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}