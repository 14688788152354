section.feature-forms {

    .column {
        position: relative;
        margin-bottom: 2em;

        // img {
        //     max-height: 400px;
        //     clip-path: inset(0 30% 0 30%);
            
        //     /* Center the image horizontally within the container */
        //     display: block;
        //     margin-left: auto;
        //     margin-right: auto;
        //     transform: translateX(-30%);
            
        //     /* Rounded border */
        //     border-radius: 20px;
        // }


    }

    .image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        min-width: 300px;
        border-radius: 20px;
        height: 300px;

        img {
            object-fit: cover;
            max-height: 145%;
        }
    }

    h3 {
        text-align: center;
        padding-top: 0.7em;
        font-size: 1.7em;
    }

    p {
        min-height: 3em;
        text-align: center;
    }

    .cta {
        text-align: center;
        margin-top: 2em;
    }
}