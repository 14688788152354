/* -------------------------------------------------------------------------- */
/*                                Falcon Button                               */
/* -------------------------------------------------------------------------- */
/* prettier-ignore */
/*# sourceMappingURL=user.css.map */

/* .how-it-works-number justify-center on bootstrap sm dimensions, for larger justify right */

// include index_app_section
@use "index/app_section";
@use "index/issue_form_section";
@use "index/discord_report_section";
@use "index/trusted_by_section";
@use "index/integrations";
@use "index/feature_forms";
@use "index/social_developers";
@use "pricing";
@use "blog";
@use "resources";
@use "components/discord_message";

:root {
  --falcon-primary-text-emphasis: #abcaf5;
  --falcon-secondary-text-emphasis: #c7cdd4;
  --falcon-success-text-emphasis: #73e6b6;
  --falcon-info-text-emphasis: #93defe;
  --falcon-warning-text-emphasis: #fbc6a8;
  --falcon-danger-text-emphasis: #f4a5b3;
  --falcon-light-text-emphasis: #646465;
  --falcon-dark-text-emphasis: #91979e;
  --falcon-primary-bg-subtle: #0f2b50;
  --falcon-secondary-bg-subtle: #292d34;
  --falcon-success-bg-subtle: #003f25;
  --falcon-info-bg-subtle: #0c384c;
  --falcon-warning-bg-subtle: #562d16;
  --falcon-danger-bg-subtle: #5c1623;
  --falcon-light-bg-subtle: #fdfefe;
  --falcon-dark-bg-subtle: #08101b;
  --falcon-primary-border-subtle: #163e73;
  --falcon-secondary-border-subtle: #3a414a;
  --falcon-success-border-subtle: #00693d;
  --falcon-info-border-subtle: #145e7f;
  --falcon-warning-border-subtle: #7b401f;
  --falcon-danger-border-subtle: #731c2c;
  --falcon-light-border-subtle: #5e6e82;
  --falcon-dark-border-subtle: #4d5969;
}

.how-it-works-number {
    font-size: 48px;
    line-height: 48px;
    align-items: start;
    margin-bottom: 15px;
}

@media (min-width: 768px) {
  .how-it-works-number {
    justify-content: right;
  }
}

@media (max-width: 767px) {
  .how-it-works-number {
    justify-content: center;
  }
}

.text-center-sm {
  text-align: center;
}

@media (max-width: 767px) {
  .text-center-sm {
    text-align: left;
  }
}

@media (min-width: 768px) {
  .text-begin-md {
    text-align: left !important;
  }
}

@media (min-width: 992px) {
  .text-begin-lg {
    text-align: left !important;
  }

  .text-center-lg {
    text-align: center !important;
  }

  .text-end-lg {
    text-align: right !important;
  }
}

@media (min-width: 768px) {
  .text-left-md {
    text-align: left;
  }
}

.text-shadow {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
}

.video-border {
  border: 3px solid #000; /* You can adjust the border width and color as needed */
  border-radius: 10px; /* This creates rounded corners. Adjust the value as desired */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow for depth */
}

/*   dimmed background image */
.dimmed-background-lg {
  filter: brightness(0.7);
}

@media (max-width: 767px) {
  .dimmed-background-lg {
    filter: brightness(1);
  }
}

.spot-illustation {
  max-width: 300px;
  max-height: 300px;
}

.betahub-logo {
  max-height: 35px;
}

.background-1 {
  background-image: url('../img/bg/gaming-1.jpg');
}

@supports (background-image: url('../img/bg/gaming-1.webp')) {
  .background-1 {
    background-image: url('../img/bg/gaming-1.webp');
  }
}

.background-2 {
  // vertical gradient
  background: linear-gradient(to bottom, rgb(2, 30, 60) 0%, rgba(35, 46, 60, 0) 100%);
}

.home-banner-container {
  @media (max-width: 768px) {
    min-height: 850px;
  }

  @media (max-width: 1200px) {
    min-height: 950px;
  }
  
}

.bg-holder.overlay:before {
  background-color: #0b172700;
}

@supports (background-image: url('../img/bg/gaming-4.webp')) {
  .background-2 {
    // background-image: url('../img/bg/gaming-4.webp');
  }
}

@media (min-width: 1200px) {
  .background-2::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    // background: linear-gradient(to right, rgba(0, 0, 0, 0.7), transparent);
} 
}

.image-rounded {
  border-radius: 15px;
}

.image-shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.image-shadow-white {
  box-shadow: 0 0px 6px rgba(var(--shadow-color), 0.3);
}

.visible {
  opacity: 1 !important;
}

/* -------------------------------------------------------------------------- */
/* Features-specific */
/* -------------------------------------------------------------------------- */

.feature-arrow-right {
  display: none;
}

/* @media (max-width: 992px) {
  .feature-arrow-right {
    display: none;
  }
} */

.feature-arrow-down {
}

/* @media (min-width: 992px) {
  .feature-arrow-down {
    display: none;
  }
} */

.feature-ai-image-container {
  position: relative;
  width: 100%;
  padding-bottom: 33.03%; /* for an aspect ratio of 16:9. Adjust as needed */
  overflow: hidden;
}

.feature-ai-fade-in-out {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 2s;
}

/* -------------------------------------------------------------------------- */
/* Posts */
/* -------------------------------------------------------------------------- */

.aspect-ratio-16-9 {
  position: relative;
  height: auto;
  aspect-ratio: 16 / 9;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 1em;
  border-radius: 20px;
}



.text-shadow-strong {
  text-shadow: 0 0 5px rgba(0, 0, 0, 1), 0 0 10px rgba(0, 0, 0, 1), 0 0 15px rgba(0, 0, 0, 1);
}

.tooltip-question-mark { 
  color: var(--falcon-primary);
  cursor: pointer;
}

figure,pre,div.highlight {
  padding: 5px;
  border-radius: 10px;
}

div.highlighter-rouge {
  margin-bottom: 8px;
}

section {
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
@media (min-width: 992px) {
  section {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.other-features {
  color: #ccc;

  a {
    color: #ccc;
    text-decoration: none;
  }

  .card {
    cursor: pointer;
  }

  .card:hover {
    color: #fff;
  }

  .name {
    // do not word wrap
    white-space: nowrap;
  }
}

@keyframes pulse {
  0% { 
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(var(--falcon-danger-rgb), 0.7);
  }
  50% { 
    transform: scale(1.2);
    box-shadow: 0 0 0 10px rgba(var(--falcon-danger-rgb), 0);
  }
  100% { 
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(var(--falcon-danger-rgb), 0);
  }
}

.recording-circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--falcon-danger);
  animation: pulse 1s infinite;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 50%;
  }
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0.7; }
  50% { opacity: 1; }
}

.recording-label {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--falcon-danger);
  animation: fadeInOut 2s infinite;
}

.blur-behind {
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.navbar-nav {
  .nav-item {
    font-size: 1.3em;
    font-weight: 500;
  }
}

.navbar-feature-item {

  .navbar-icon-container {
    min-width: 40px;
    height: 40px;
    margin: 10px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: #fff !important;
      scale: 1.5;

      animation: rotate-navbar-icon-out 0.3s forwards;
    }
  }

  &:hover .navbar-icon-container svg {
    // animate and and hold on hover
    animation: rotate-navbar-icon-in 0.3s forwards;
  }
}

// animation for navbar icon container to rotate a bit right and left
@keyframes rotate-navbar-icon-in {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(20deg); }
}

@keyframes rotate-navbar-icon-out {
  from { transform: rotate(20deg); }
  to { transform: rotate(0deg); }
}

@media (min-width: 992px) {
  .animate {
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}

// renders a pseudo-border around the image
.bordered-image {
  display: flex;
  align-items: center;
  justify-content: center;


  // inner should take as 
  .bordered-image-inner {
    padding: 18px;
    border-radius: 20px;
    border: 1px solid #444;
    // delicate background gradient
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05));

    box-shadow: rgba(255, 255, 255, 0.05) 0 0 20px;
  }

  img,video {
    border-radius: 10px;
  }
}

.kitsu {
  bottom: 0px;
  max-width: 250px;
  max-height: 250px;
  opacity: 0.8;
}

.badge-subtle-primary {
  color: var(--falcon-primary-text-emphasis);
  background-color: var(--falcon-primary-bg-subtle);
}
.badge-subtle-primary[href]:hover, .badge-subtle-primary[href]:focus {
  text-decoration: none;
  color: var(--falcon-primary-text-emphasis);
  background-color: var(--falcon-primary-bg-subtle);
}

.badge-subtle-secondary {
  color: var(--falcon-secondary-text-emphasis);
  background-color: var(--falcon-secondary-bg-subtle);
}
.badge-subtle-secondary[href]:hover, .badge-subtle-secondary[href]:focus {
  text-decoration: none;
  color: var(--falcon-secondary-text-emphasis);
  background-color: var(--falcon-secondary-bg-subtle);
}

.badge-subtle-success {
  color: var(--falcon-success-text-emphasis);
  background-color: var(--falcon-success-bg-subtle);
}
.badge-subtle-success[href]:hover, .badge-subtle-success[href]:focus {
  text-decoration: none;
  color: var(--falcon-success-text-emphasis);
  background-color: var(--falcon-success-bg-subtle);
}

.badge-subtle-info {
  color: var(--falcon-info-text-emphasis);
  background-color: var(--falcon-info-bg-subtle);
}
.badge-subtle-info[href]:hover, .badge-subtle-info[href]:focus {
  text-decoration: none;
  color: var(--falcon-info-text-emphasis);
  background-color: var(--falcon-info-bg-subtle);
}

.badge-subtle-warning {
  color: var(--falcon-warning-text-emphasis);
  background-color: var(--falcon-warning-bg-subtle);
}
.badge-subtle-warning[href]:hover, .badge-subtle-warning[href]:focus {
  text-decoration: none;
  color: var(--falcon-warning-text-emphasis);
  background-color: var(--falcon-warning-bg-subtle);
}

.badge-subtle-danger {
  color: var(--falcon-danger-text-emphasis);
  background-color: var(--falcon-danger-bg-subtle);
}
.badge-subtle-danger[href]:hover, .badge-subtle-danger[href]:focus {
  text-decoration: none;
  color: var(--falcon-danger-text-emphasis);
  background-color: var(--falcon-danger-bg-subtle);
}

.badge-subtle-light {
  color: var(--falcon-light-text-emphasis);
  background-color: var(--falcon-light-bg-subtle);
}
.badge-subtle-light[href]:hover, .badge-subtle-light[href]:focus {
  text-decoration: none;
  color: var(--falcon-light-text-emphasis);
  background-color: var(--falcon-light-bg-subtle);
}

.badge-subtle-dark {
  color: var(--falcon-dark-text-emphasis);
  background-color: var(--falcon-dark-bg-subtle);
}
.badge-subtle-dark[href]:hover, .badge-subtle-dark[href]:focus {
  text-decoration: none;
  color: var(--falcon-dark-text-emphasis);
  background-color: var(--falcon-dark-bg-subtle);
}