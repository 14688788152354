#resources .cover-image {
  width: 100%;
  margin-bottom: 1em;
  border-radius: 20px;
}

#resources .resource-card {
  transition: all 0.3s ease;
  border: 2px solid transparent;
}

#resources .resource-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0,0,0,0.1);
  border-color: #6C5CE7;
}

#resources .resource-card-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

#resources .resource-card-link:hover {
  text-decoration: none;
}

#resources .tag-link {
  color: #6C5CE7;
  text-decoration: none;
}

#resources .tag-link:hover {
  text-decoration: underline;
}

#resources .badge {
  margin-right: 5px;
}


#resource {
  table {
      color: white;
      border-color: #aaa;
  }

  img {
    max-width: 100%;
    // center it
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.resource-container {
  padding: 25px;
  background: hsla(0, 0%, 0%, 0.3);
  background: linear-gradient(180deg, hsla(0, 0%, 0%, 0.3) 0%, hsla(0, 0%, 0%, 0.0) 100%);
  border-radius: 15px;

  line-height: 1.6;

  img, video {
    max-width: 100%;
    height: auto;
  }

  h2,h3,h4,h5,h6 {
    margin-top: 1em;
  }
}

div.resource-cover-container {
  background-size: cover; /* This will cover the entire area of the container */
  background-position: center; /* This centers the image in the container */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  margin-bottom: 1em;
}

.resource-cover-container h1 {
  font-size: 2em;
}

.resource-grid {
  padding: 2rem 0;
  color: white;

  h2 {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2.5rem;
  }

  h3 {
    color: black;
  }

  .resource-container {
    // display: flex;
    gap: 1.5rem;
    padding: 0 1rem;
  }

  .resource-card {
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    max-width: 300px;
  }

  .resource-card:hover {
    transform: translateY(-5px);
  }

  .resource-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  .resource-content {
    padding: 1rem;
    color: #333;
  }

  .resource-content h3 {
    margin-top: 0;
    font-size: 1.2rem;
  }

  .resource-content p {
    font-size: 0.9rem;
    margin-bottom: 0;
  }

  *:hover {
    text-decoration: none;
  }
}