/* define shadow-color variable */
.app-view {    
    .app-view-container {
        --shadow-color: 200, 200, 255;

      position: relative;
      // max-width: 80%;
      display: inline-block;
      box-shadow: 0 0 0 1px rgba(var(--shadow-color), 0.05),
        0 1px 0 0 rgba(var(--shadow-color), 0.05),
        0 0.2em 1.6em -0.8em rgba(var(--shadow-color), 0.2),
        0 0.4em 2.4em -1em rgba(var(--shadow-color), 0.3),
        0 0.4em 0.8em -1.2em rgba(var(--shadow-color), 0.4),
        0 0.8em 1.2em -1.6em rgba(var(--shadow-color), 0.5),
        0 1.2em 1.6em -2em rgba(var(--shadow-color), 0.6);
      border-radius: 15px;
    }
    
    .app-view-screenshot {
      max-width: 100%;
      /* mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 70%, rgba(0,0,0,0) 100%); */
      border: 1px solid #455468;
      border-radius: 15px;
    }

    .arrows {
        // background-color: rgba(255, 0, 0, 0.3);
        height: 100%;
        left: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 100%;
    
        div {
          position: absolute;
        }
    
        svg.arrow path {
          fill: rgb(255, 183, 0);
        }
    
        .arrow-label {
          position: absolute;
          font-family: "Short Stack", cursive;
          color: rgb(255, 217, 122);
          text-align: center;
          // add big black shadow
          text-shadow: 0 0 10px black, 0 0 20px black, 0 0 20px black;
    
          // font size relative to container
          font-size: 0.3em;

          @media (min-width: 576px) {
            font-size: 0.35em;
          }

          @media (min-width: 768px) {
            font-size: 0.5em;
          }

          @media (min-width: 992px) {
            font-size: 0.65em;
          }

          @media (min-width: 1200px) {
            font-size: 0.8em;
          }

          @media (min-width: 1540px) {
            font-size: 1.05em;
          }

          // make it all caps
          text-transform: uppercase;
          font-weight: bold;
        }
      }
  }