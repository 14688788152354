section.trusted-by {
    .logos {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 60px;
    }

    a {
        // width: 320px;
       
        display: inline-block;
        transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

        // animate opacity to 1
        opacity: 0.6;

        &:hover {
            transform: translateY(-5px);
            opacity: 1;
        }

        img {
            max-height: 40px;
            max-width: 100%;
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}