section.integrations {
    .screenshots-animation {
        height: 500px;

        @media (max-width: 1540px) {
            height: 300px;
        }

        position: relative;

        img {
            max-width: 85%;
            position: absolute;

            box-shadow: 0 4px 10px rgba(59, 0, 99, 0.5);

            // round corners
            border-radius: 15px;
        }

        // first img should be straight
        // second img should be slightly rotated right, anchor to bottom-left
        // third img should be rotated right even more, anchor to bottom-left
        // second and third img should animate in a loop, moving up and down

        img:nth-child(2) {
            transform: rotate(4deg);
            transform-origin: bottom left;
            animation: rotate 5s infinite;
            left: 40px;
            top: 20px;

            @keyframes rotate {
                0% {
                    transform: rotate(4deg);
                }
                50% {
                    transform: rotate(6deg);
                }
                100% {
                    transform: rotate(4deg);
                }
            }
        }

        img:nth-child(3) {
            transform: rotate(8deg);
            transform-origin: bottom left;
            animation: rotate2 7s infinite;
            left: 80px;
            top: 40px;

            @keyframes rotate2 {
                0% {
                    transform: rotate(8deg);
                }
                50% {
                    transform: rotate(10deg);
                }
                100% {
                    transform: rotate(8deg);
                }
            }
        }
        
    }
}