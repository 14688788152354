#post {
    table {
        color: white;
        border-color: #aaa;
    }

    // p element that is directly above img element
    p:has(img) {
      text-align: center;
    }

    ul {
      position: relative;
    }
    ul li {
      position: relative;
      left: 0;
      color: #fce4ec;
      list-style: none;
      margin: 6px 0;
      padding-left: 10px;
      border-left: 3px solid #53d218;
      transition: 0.5s;
      cursor: pointer;
    }
    
    ul li span {
      position: relative;
      padding: 8px;
      padding-left: 12px;
      display: inline-block;
      z-index: 1;
      transition: 0.5s;
    }
    
    ul li:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: #53d218;
      transform: scaleX(0);
      transform-origin: left;
      transition: 0.5s;
    }
}

#blog-posts .cover-image {
  width: 100%;
  margin-bottom: 1em;
  border-radius: 20px;

  &:hover {
    filter: brightness(0.8);
  }
}

.post-container {
  padding: 25px;
  background: hsla(0, 0%, 0%, 0.3);
  background: linear-gradient(180deg, hsla(0, 0%, 0%, 0.3) 0%, hsla(0, 0%, 0%, 0.0) 100%);
  border-radius: 15px;

  line-height: 1.6;

  img, video {
    max-width: 100%;
    height: auto;
  }

  h2,h3,h4,h5,h6 {
    margin-top: 1em;
  }

  strong {
    color: white;
  }

  img.centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

div.post-cover-container {
  background-size: cover; /* This will cover the entire area of the container */
  background-position: center; /* This centers the image in the container */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  margin-bottom: 1em;
}

.post-cover-container h1 {
  font-size: 2em;
}

.alert {
  // last p
  p:last-child {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
}