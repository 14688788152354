.pricing-column {
    .pricing-features-list {
        font-size: 0.9em;
        color: #ccc;

        min-height: 250px;

        @media (max-width: 1539px) {
            min-height: 350px;
        }

        @media (max-width: 1199px) {
            min-height: 400px;
        }

        @media (max-width: 991px) {
            min-height: 300px;
        }
    }
}

#pricing-comparison {
    th {
        color: white;
    }

    // first td in each row
    td:first-child {
        color: white;
        font-weight: bold;
    }

    td {
        color: #ccc;
    }

    .price-row {
        font-size: 1.2em;
    }

    .group-row {
        font-size: 1.5em;
    }
}

#pricing-faq {
    .faq-question {
        color: white;
        font-weight: 500;
    }

    .faq-answer {
        // color: white;
    }
}