/* Discord Message Component Styles */
.discord-message-wrapper {
  background-color: #36393f;
  border-radius: 8px;
  font-family: 'Whitney', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #dcddde;
  overflow: hidden;
  padding: 16px;
  margin: 20px 0;
  width: 100%;
  max-width: 600px;
}

.discord-message {
  display: flex;
  padding: 2px 0;
}

.discord-message-avatar {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  margin-right: 16px;
  flex-shrink: 0;
}

.discord-message-content {
  flex-grow: 1;
  min-width: 0;
}

.discord-message-header {
  display: flex;
  align-items: baseline;
  margin-bottom: 4px;
}

.discord-message-username {
  font-weight: 500;
  font-size: 1rem;
  margin-right: 8px;
  color: white;
}

.discord-message-time {
  font-size: 0.75rem;
  color: #72767d;
}

.discord-message-text {
  font-size: 0.9375rem;
  line-height: 1.3;
  white-space: pre-wrap;
  word-wrap: break-word;
} 